@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

nb-card {
  border-radius: 20px;
  box-shadow: 1px 0 20px #00000014;
}

button {
  border-radius: 20px !important;
}

input {
  border-radius: 20px !important;
}

nb-accordion {
  border-radius: 20px !important;
  box-shadow: 1px 0 20px #00000014;
}

nb-accordion-item:first-child {
  border-radius: 20px 20px 0 0 !important;
}

nb-accordion-item:last-child {
  border-radius: 0 0 20px 20px !important;
}

nb-window {
  max-height: 100vh;
  overflow-y: auto;
}

.cancel-button {
  // Pull the cancel button away from confirmation button
  margin-right: 15px;
}

@media (max-width: 600px) {
  // Reduce header height on smaller screens
  nb-layout-header nav {
    height: 4rem!important;
  }
  // Hide the User name and email from the header
  .header-container nb-user .info-container {
    display: none !important;
  }

  nb-window {
    max-height: 80vh;
  }
}

.accordion-card-footer {
  padding: 0 !important;
}

.max-width-90 {
  max-width: 90vw;
}
